<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline"> Edit Venue Season </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="season-form">
          <v-text-field
            label="Price *"
            v-model="fields.price"
            type="number"
            step="0.01"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="season-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      season: {},
      price: {},
      fields: {
        price: null,
        season_id: null,
      },
      errors: {},
    };
  },

  methods: {
    open(season = null, price = null) {
      if (price !== null) {
        this.isEditing = true;
        this.fields.price = price;
      }
      this.season = season;
      this.fields.season_id = this.season;
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        venueId: this.$route.params.venueId,
        fields: this.fields,
      };

      this.$store
        .dispatch("craigtoun/venuesStore/saveSeasonPrice", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.season = {};
      this.price = {};

      this.fields = {
        price: null,
        season_id: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
