<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>{{ venue.name }}</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  class="mr-2"
                  :to="{ name: 'module-craigtoun-venues-list' }"
                >
                  <v-icon small>mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed color="blue white--text" v-on="on">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Venue</span>
            </v-tooltip>-->
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">
              {{ venue.name }} Bookings
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    v-on="on"
                    class="mr-2"
                    :to="{
                      name: 'module-craigtoun-venues-individual-archived',
                    }"
                  >
                    <v-icon small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>View Archived Bookings</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.eventDialog.open()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Hire</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="eventsTableHeaders"
              :items="events"
              no-data-text="No Events have been booked"
            >
              <template v-slot:item.date="{ item }">
                {{ item.formatted_dates.date }}
              </template>
              <template v-slot:item.customer="{ item }">
                <div v-if="item.customer">
                  <router-link
                    :to="{
                      name: 'module-craigtoun-customers-individual',
                      params: { customerId: item.customer.id },
                    }"
                    >{{ item.customer.full_name }}</router-link
                  >
                </div>
                <div v-else>{{ item.customer_name }}</div>
              </template>
              <template v-slot:item.craigtoun_responded="{ item }">
                <v-chip
                  label
                  small
                  color="green white--text"
                  v-if="item.craigtoun_responded"
                  >Yes</v-chip
                >
                <v-chip label small color="red white--text" v-else>No</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.eventDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="openDelete(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
          <v-card outlined>
            <v-card-title class="grey lighten-4">Calendar</v-card-title>
            <v-divider></v-divider>
            <venue-calendar :events="events" />
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">Seasons</v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Hire Price</th>
                  <th class="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="season in prices"
                  v-bind:key="season.id"
                  class="green lighten-4"
                >
                  <td>{{ season.formatted_dates.start_date }}</td>
                  <td>{{ season.formatted_dates.end_date }}</td>
                  <td>
                    <div v-if="season.prices.length > 0">
                      £{{ season.prices[0].price }}
                    </div>
                  </td>
                  <td class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="season.prices.length > 0"
                          x-small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          @click="
                            $refs.venueSeasonDialog.open(
                              season.id,
                              season.prices[0].price
                            )
                          "
                        >
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn
                          v-if="season.prices.length == 0"
                          x-small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          @click="$refs.venueSeasonDialog.open(season.id)"
                        >
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <v-card outlined>
            <v-card-title class="grey lighten-4">Available Extras</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-switch
                v-for="extra in extras"
                :key="extra.id"
                v-model="selectedExtras"
                color="green"
                :label="extra.name"
                :value="extra.id"
                inset
                hide-details
                :loading="extrasLoading"
              ></v-switch>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <venue-season-dialog ref="venueSeasonDialog" />
    <event-dialog ref="eventDialog" :isNotVenuePage="true" />
    <v-dialog v-model="deleteBooking.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Event</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteBooking.booking.id }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteBooking.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventDialog from "./components/EventDialog";
import VenueCalendar from "./components/VenueCalendar.vue";
import VenueSeasonDialog from "./components/VenueSeasonDialog.vue";

export default {
  components: {
    EventDialog,
    VenueSeasonDialog,
    VenueCalendar,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Venue Hires",
          disabled: false,
          to: {
            name: "module-craigtoun-venues",
          },
          exact: true,
        },
        {
          text: "Venues",
          disabled: false,
          to: {
            name: "module-craigtoun-venues-list",
          },
          exact: true,
        },
        {
          text: "Venue",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      eventsTableHeaders: [
        { text: "Date", value: "date" },
        { text: "Customer", value: "customer" },
        { text: "Total Attendees", value: "total_attendees" },
        { text: "Start Time", value: "start_time" },
        { text: "Craigtoun Responded?", value: "craigtoun_responded" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteBooking: {
        dialog: false,
        booking: {},
        loading: false,
      },
      selectedExtras: [],
      extrasLoading: false,
    };
  },

  computed: {
    venue() {
      return this.$store.getters["craigtoun/venuesStore/get"];
    },

    events() {
      let bookings = this.$store.getters[
        "craigtoun/venuesStore/bookingsAll"
      ].filter((c) => {
        return c.venue_id == this.venue.id;
      });

      bookings.map((item) => {
        item.start = new Date(item.start);
        item.color = item.venue.color;
        item.name = item.venue.name;
      });

      return bookings;
    },

    prices() {
      let prices = this.$store.getters["craigtoun/venuesStore/prices"];
      return prices;
    },

    extras() {
      return this.$store.getters["craigtoun/venuesStore/extras"];
    },
  },

  watch: {
    selectedExtras() {
      this.saveExtras();
    },
  },

  methods: {
    openDelete(booking) {
      this.deleteBooking.booking = booking;
      this.deleteBooking.dialog = true;
    },

    resetDelete() {
      this.deleteBooking.dialog = false;
      this.deleteBooking.booking = {};
      this.deleteBooking.loading = false;
    },

    saveDelete() {
      this.deleteBooking.loading = true;

      this.$store
        .dispatch("craigtoun/venuesStore/deleteBooking", {
          appId: this.$route.params.id,
          bookingId: this.deleteBooking.booking.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteBooking.loading = false;
        });
    },

    saveExtras() {
      this.extrasLoading = true;

      this.$store
        .dispatch("craigtoun/venuesStore/saveExtras", {
          appId: this.$route.params.id,
          venueId: this.$route.params.venueId,
          fields: {
            extras: this.selectedExtras,
          },
        })
        .then(() => {
          this.extrasLoading = false;
        })
        .catch(() => {
          this.extrasLoading = false;
        });
    },

    parseExtras() {
      this.selectedExtras = this.venue.optional_extras.map((e) => e.id);
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.venue.name,
      disabled: true,
    });

    this.parseExtras();
  },
};
</script>
